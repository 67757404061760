import orderCountMonthly from './orderCountMonthly';
import orderCountYearly from './orderCountYearly';
import orderCountMonthlyGrapher from './orderCountMonthlyGrapher';
import orderCountYearlyGrapher from './orderCountYearlyGrapher';
import orderRevenueMonthly from './orderRevenueMonthly';
import orderRevenueYearly from './orderRevenueYearly';

export default {
  orderRevenueYearly: {
    role: '_',
    component: orderRevenueYearly,
  },
  orderCountYearly: {
    role: '_',
    component: orderCountYearly,
  },
  orderRevenueMonthly: {
    role: 'TEMSILCI',
    component: orderRevenueMonthly,
  },
  orderCountMonthly: {
    role: 'TEMSILCI',
    component: orderCountMonthly,
  },
  orderCountYearlyGrapher: {
    role: '_',
    // role: 'GRAFIK',
    component: orderCountYearlyGrapher,
  },
  orderCountMonthlyGrapher: {
    role: '_',
    // role: 'GRAFIK',
    component: orderCountMonthlyGrapher,
  },
};
