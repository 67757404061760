import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Image, Checkbox } from 'antd';
import { GlobalContext } from '../../contexts/global';
import collections from '../../utils/collections';
import { formatDate, formatK, renderXNumber } from '../../utils/common';
import { getDoc } from '../../utils/firebase';

const DATE_FORMAT = 'DD/MM/YYYY';

export default function Orders() {
  const { orderId } = useParams();
  const [orderDetail, setOrderDetail] = useState(null);
  const [offer, setOffer] = useState(null);
  const [bicak, setBicak] = useState({
    type: '--',
    yanyana: '--',
    arabosluk: '--',
    zet: '--',
  });
  const [klise, setKlise] = useState({
    type: '--',
    yanyana: '--',
    arabosluk: '--',
    zet: '--',
  });

  async function getOrderDetail() {
    await getDoc(collections.ORDERS, orderId)
      .then((data) => {
        // No more radio, now multi select.
        if (data.roll_direction && !Array.isArray(data.roll_direction)) {
          data.roll_direction = [data.roll_direction];
        }

        setOrderDetail(data);
        setOffer(data.offer);
        checkBicak(data);
        checkKlise(data);
        setTimeout(() => {
          handlePrint();
        }, 100);
      });
  }

  function checkBicak(data) {
    if (data.vorey_bicak === 'Var') {
      setBicak({
        type: 'Vorey Kesim',
        yanyana: '--',
        arabosluk: '--',
        zet: '--',
      });
    } else if (data.tahta_bicak === 'Var') {
      setBicak({
        type: 'Tahta',
        yanyana: data.tahta_bicak_yanyana,
        arabosluk: data.tahta_bicak_arabosluk,
        zet: '--',
      });
    } else if (data.yaprak_bicak === 'Var') {
      setBicak({
        type: 'Yaprak',
        yanyana: data.yaprak_bicak_yanyana,
        arabosluk: data.yaprak_bicak_arabosluk,
        zet: data.yaprak_bicak_zet,
      });
    }
  }

  function checkKlise(data) {
    if (data.print_type === 'Flekso' && data.flekso_klise === 'Var') {
      setKlise({
        type: 'Flekso/Klişe',
        yanyana: data.flekso_klise_yanyana,
        arabosluk: data.flekso_klise_arabosluk,
        zet: data.flekso_klise_zet,
      });
    }
  }

  const { getPaper, getUserFullName } = useContext(GlobalContext);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    document.title = `Asal_IsEmri_${orderId}`;
    getOrderDetail();
  }, [orderId]);

  return (
    <div ref={printRef} className="print-wrapper" lang="tr">
      {orderDetail && offer && (
        <table className="print-table">
          <tr>
            <td colSpan="3" className="no-padding no-border">
              <table className="print-table">
                <tr>
                  <td width="33.333%" className="no-padding">
                    <img src="/logo.png" alt="logo" height={100} />
                  </td>
                  <td style={{ textAlign: 'center', fontWeight: 'bold', verticalAlign: 'middle' }}>İş Emri</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr><td colSpan="3" className="no-border" /></tr>
          <tr className="no-border">
            <table className="print-table">
              <tr>
                <td colSpan="3" className="no-padding no-border">
                  <table className="print-table">
                    <tr>
                      <td width="50%">
                        <span className="title">Grafiker</span>
                        {getUserFullName(orderDetail.grapher) || '--'}
                      </td>
                      <td width="50%">
                        <span className="title">Firma Ticaret Ünvanı</span>
                        {offer.client_name}
                      </td>
                    </tr>
                    <tr>
                      <td width="50%">
                        <span className="title">Satış Temsilcisi</span>
                        {getUserFullName(offer.client_representative)}
                      </td>
                      <td width="50%">
                        <span className="title">İşin Adı</span>
                        {offer.name}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td width="33.333%">
                  <span className="title">Sipariş Tarihi</span>
                  {formatDate(orderDetail.order_date, DATE_FORMAT) || '--'}
                </td>
                <td width="33.333%">
                  <span className="title">Baskı Onay Tarihi</span>
                  {formatDate(orderDetail.print_approval_date, DATE_FORMAT) || '--'}
                </td>
                <td width="33.333%">
                  <span className="title">Üretim Onay Tarihi</span>
                  {formatDate(orderDetail.production_start_date, DATE_FORMAT) || '--'}
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td colSpan="2">
                  <span className="title">Baskı Türü</span>
                  {orderDetail.print_type || '--'}
                </td>
                <td colSpan="1">
                  <span className="title">Teklif Numarası</span>
                  {renderXNumber(offer.client_number, offer.offer_number)}
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td colSpan="3" className="no-padding no-border">
                  <table className="print-table" style={{ marginBottom: '-1px' }}>
                    <tr>
                      <td width="25%">
                        <span className="title">Etiket Adedi</span>
                        {orderDetail.total_piece ? formatK(orderDetail.total_piece, 0) : '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Kağıt Türü</span>
                        {getPaper(offer?.material)?.name || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Etiket En (mm)</span>
                        {offer?.en || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Etiket Boy (mm)</span>
                        {offer?.boy || '--'}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="no-padding no-border">
                  <table className="print-table" style={{ marginBottom: '-1px' }}>
                    <tr>
                      <td width="25%">
                        <span className="title">Malzeme Kodu</span>
                        {orderDetail.stock_material_code || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Bobin En (mm)</span>
                        {orderDetail.stock_en || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Bobin Boy (metre)</span>
                        {orderDetail.stock_boy?.toLocaleString('tr') || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Tüketilen Boy</span>
                        {orderDetail.stock_consumed_size?.filter((s) => Object.values(s)[0]).map((s) => Object.values(s)[0]).join(' + ') || '--'}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td colSpan="3" className="no-padding no-border">
                  <table className="print-table">
                    <tr>
                      <td width="12%">
                        <span className="title">Renk</span>
                        {orderDetail.color?.join(', ') || '--'}
                      </td>
                      <td width="10%">
                        <span className="title">P1</span>
                        {orderDetail.p1 || '--'}
                      </td>
                      <td width="10%">
                        <span className="title">P2</span>
                        {orderDetail.p2 || '--'}
                      </td>
                      <td width="16%">
                        <span className="title">Etiket Şekli</span>
                        {orderDetail.label_shape || '--'}
                      </td>
                      <td width="12%">
                        <span className="title">Lak</span>
                        {orderDetail.lak || '--'}
                      </td>
                      <td width="15%">
                        <span className="title">Selofan</span>
                        {orderDetail.selofan || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Diğer</span>
                        {orderDetail.others?.join(', ') || '--'}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td colSpan="3" className="no-padding no-border">
                  <table className="print-table">
                    <tr>
                      <td width="50%">
                        <span className="title">Yapıştırma</span>
                        {orderDetail.bonding_type || '--'}
                      </td>
                      <td width="50%">
                        <span className="title">Kuka</span>
                        {orderDetail.kuka || '--'}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className={`middle t-${orderDetail.roll_direction ? 'center' : 'left'}`}>
                        <span className="title t-left">Rulo Yönü</span>
                        {orderDetail.roll_direction ? (
                          <Checkbox.Group name="roll_direction_radio" value={orderDetail.roll_direction} className="x-with-border print-checkbox">
                            <Checkbox value={1}>
                              <p className="t-center mb-0">1</p>
                              <Image width={60} preview={false} src="/images/YON01.png" />
                            </Checkbox>
                            <Checkbox value={2}>
                              <p className="t-center mb-0">2</p>
                              <Image width={60} preview={false} src="/images/YON02.png" />
                            </Checkbox>
                            <Checkbox value={3}>
                              <p className="t-center mb-0">3</p>
                              <Image width={60} preview={false} src="/images/YON03.png" />
                            </Checkbox>
                            <Checkbox value={4}>
                              <p className="t-center mb-0">4</p>
                              <Image width={60} preview={false} src="/images/YON04.png" />
                            </Checkbox>
                            <Checkbox value={5}>
                              <p className="t-center mb-0">5</p>
                              <Image width={60} preview={false} src="/images/YON05.png" />
                            </Checkbox>
                            <Checkbox value={6}>
                              <p className="t-center mb-0">6</p>
                              <Image width={60} preview={false} src="/images/YON06.png" />
                            </Checkbox>
                            <Checkbox value={7}>
                              <p className="t-center mb-0">7</p>
                              <Image width={60} preview={false} src="/images/YON07.png" />
                            </Checkbox>
                            <Checkbox value={8}>
                              <p className="t-center mb-0">8</p>
                              <Image width={60} preview={false} src="/images/YON08.png" />
                            </Checkbox>
                          </Checkbox.Group>
                        ) : '--'}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td colSpan="3" className="no-padding no-border">
                  <table className="print-table" style={{ marginBottom: '-1px' }}>
                    <tr>
                      <td width="25%">
                        <span className="title">Bıçak Bilgileri</span>
                        {bicak.type || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Yan Yana</span>
                        {bicak.yanyana || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Ara Boşluk</span>
                        {bicak.arabosluk?.toLocaleString('tr') || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Zet</span>
                        {bicak.zet || '--'}
                      </td>
                    </tr>
                  </table>
                  <table className="print-table">
                    <tr>
                      <td width="25%">
                        <span className="title">Klişe Bilgileri</span>
                        {klise.type || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Yan Yana</span>
                        {klise.yanyana || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Ara Boşluk</span>
                        {klise.arabosluk?.toLocaleString('tr') || '--'}
                      </td>
                      <td width="25%">
                        <span className="title">Zet</span>
                        {klise.zet || '--'}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" style={{ borderTop: 0 }}>
                        <span className="title">Bıçak Numarası</span>
                        {orderDetail.bicak_no || '--'}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td colSpan="3" className="no-padding no-border">
                  <table className="print-table">
                    <tr>
                      <td width="50%">
                        <span className="title">Sarım Çapı</span>
                        {orderDetail.sarim_capi || '--'}
                      </td>
                      <td width="50%">
                        <span className="title">Sarım Adedi</span>
                        {orderDetail.sarim_adedi || '--'}
                      </td>
                    </tr>
                    <tr>
                      <td width="50%">
                        <span className="title">Toplam Etiket Adedi</span>
                        {orderDetail.toplam_etiket_adedi ? formatK(orderDetail.toplam_etiket_adedi, 0) : '--'}
                      </td>
                      <td width="50%">
                        <span className="title">Teslimat Tarihi</span>
                        {formatDate(orderDetail.shipment_date, DATE_FORMAT) || '--'}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td colSpan="3">
                  <span className="title">Notlar</span>
                  {orderDetail.notes?.length ? orderDetail.notes.split('\n').map((line) => <p className="mb-0">{line}</p>) : '--'}
                </td>
              </tr>
            </table>
          </tr>
        </table>
      )}
    </div>
  );
}
