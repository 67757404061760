import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { GlobalContext } from '../../../../contexts/global';
import { colorsAsArray, lighterColor } from '../../../../utils/colors';
import { formatDate } from '../../../../utils/common';
import {
  MONTH_FORMAT, TOTAL_LABEL, chartDefaultOptions, chartFont, customSort,
} from './common';

export default function OrderCountMonthlyGrapher({ data }) {
  const chartRef = useRef(null);
  const { getUserFullName, isAdmin, userId } = useContext(GlobalContext);
  const options = chartDefaultOptions({
    title: 'Aylık Grafik Tasarımcı Bazında Üretilen İş Adedi',
    tooltip: true,
    datalabels: ['center', false, 'auto'],
  });

  const chartOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      legend: {
        display: true,
        labels: {
          font: chartFont,
          generateLabels: (chart) => {
            const { datasets } = chart.data;
            const uniqueNames = [...new Set(datasets.map((ds) => ds.label.split(' - ')[0]))];

            return uniqueNames.map((name) => ({
              text: name,
              fillStyle: datasets.find((ds) => ds.label.startsWith(name))?.backgroundColor,
              hidden: datasets.find((ds) => ds.label.startsWith(name))?.hidden,
              index: datasets.findIndex((ds) => ds.label.startsWith(name)),
              strokeStyle: 'transparent',
              fontColor: '#666',
            }));
          },
        },
        onClick: (e, legendItem, legend) => {
          const { text: legendName } = legendItem;
          const { chart } = legend;
          const { datasets } = chart.data;

          const digitalDs = datasets.find((ds) => ds.label === `${legendName} - Digital`);
          const flexoDs = datasets.find((ds) => ds.label === `${legendName} - Flekso`);

          if (digitalDs && flexoDs) {
            const isHidden = !digitalDs.hidden;
            digitalDs.hidden = isHidden;
            flexoDs.hidden = isHidden;
            chart.update();
          }
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { dataset, label: monthLabel } = context;
            const monthData = data.find((d) => `${d.date.year}-${String(d.date.month).padStart(2, '0')}` === monthLabel);
            const baseName = dataset.label.split(' - ')[0];
            const grapherId = Object.entries(monthData.sum.byGrapher).find(
              ([id]) => getUserFullName(id) === baseName,
            )?.[0];
            const values = monthData.sum.byGrapher[grapherId] || { digital: 0, flekso: 0 };

            return `${dataset.label}: ${values[context.dataset.printType] || 0}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: chartFont,
          callback: (value, index) => {
            if (chartRef.current) {
              return formatDate(chartRef.current.data.labels[index], MONTH_FORMAT, false);
            }
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          font: chartFont,
        },
        beginAtZero: true,
        afterDataLimits: (scale) => {
          scale.max *= 1.05;
        },
      },
    },
  };

  function transformData() {
    const labels = new Set();
    const datasets = {};

    data.forEach((monthData) => {
      const monthLabel = `${monthData.date.year}-${String(monthData.date.month).padStart(2, '0')}`;
      labels.add(monthLabel);

      Object.entries(monthData.sum.byGrapher).forEach(([grapherId, values]) => {
        if (!(isAdmin || userId === grapherId)) {
          return;
        }

        const grapherName = getUserFullName(grapherId) || TOTAL_LABEL;

        if (!datasets[grapherName]) {
          datasets[grapherName] = {
            label: grapherName,
            stack: grapherName,
            data: {},
            hidden: grapherName === TOTAL_LABEL,
          };
        }

        datasets[grapherName].data[monthLabel] = {
          digital: values.digital || 0,
          flexo: values.flekso || 0,
        };
      });
    });

    const sortedLabels = Array.from(labels).sort((a, b) => a.localeCompare(b));

    Object.keys(datasets).forEach((name) => {
      sortedLabels.forEach((month) => {
        if (!datasets[name].data[month]) {
          datasets[name].data[month] = { digital: 0, flexo: 0 };
        }
      });
    });

    const sortedDatasets = Object.keys(datasets).sort(customSort).flatMap((name, index) => [
      {
        label: `${name} - Digital`,
        stack: name,
        data: sortedLabels.map((m) => datasets[name].data[m].digital),
        backgroundColor: colorsAsArray[index],
        hidden: name === TOTAL_LABEL,
        printType: 'digital',
      },
      {
        label: `${name} - Flekso`,
        stack: name,
        data: sortedLabels.map((m) => datasets[name].data[m].flexo),
        backgroundColor: lighterColor(colorsAsArray[index], 0.5),
        hidden: name === TOTAL_LABEL,
        printType: 'flekso',
      },
    ]);

    return {
      labels: sortedLabels,
      datasets: sortedDatasets,
    };
  }

  const chartData = transformData();

  return (
    <Bar
      ref={chartRef}
      options={{
        ...chartOptions,
        scales: {
          ...chartOptions.scales,
          x: {
            stacked: true,
            ticks: {
              font: chartFont,
              callback: (value, index) => formatDate(chartData.labels[index], MONTH_FORMAT, false),
            },
          },
          y: chartOptions.scales.y,
        },
      }}
      data={chartData}
    />
  );
}

OrderCountMonthlyGrapher.propTypes = {
  data: PropTypes.array,
};

OrderCountMonthlyGrapher.defaultProps = {
  data: [],
};
