/* eslint-disable react/prop-types */
import React, {
  useContext, useEffect, useState, useRef, useMemo,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card, Table, Select, Form, Input, InputNumber,
  Button, Space, Popconfirm, notification, Grid,
} from 'antd';
import {
  CopyOutlined, RetweetOutlined, DeleteOutlined, DatabaseOutlined,
} from '@ant-design/icons';
import collections from '../../utils/collections';
import {
  queryWhere, getDocs, where, and, updateDoc, addDoc, auth, deleteDoc, limit,
} from '../../utils/firebase';
import {
  formatDate, formatK, formatEuro, filterOption, generateSearchTerms,
  renderXNumber,
} from '../../utils/common';
import { GlobalContext } from '../../contexts/global';
import { setOffer } from '../../utils/calc';
import {
  SET_ASSORTMENT, SELOFAN, YALDIZ, YALDIZ_TYPE, STATUS,
} from '../../utils/options';
import routes from '../../utils/routes';
import Page404 from '../Page404';

const config = {
  okText: 'Evet',
  cancelText: 'Hayır',
};

const filterSetup = {
  placeholder: 'Seçiniz',
  filterOption,
  showSearch: true,
  allowClear: true,
  mode: 'tags',
};

const numberConf = {
  controls: false,
  min: 0,
  decimalSeparator: ',',
  style: {
    width: '100%',
  },
};

const OFFER_WIN = 'Teklif Kazanıldı';
const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];

const { useBreakpoint } = Grid;

export default function Offers() {
  const screens = useBreakpoint();
  const {
    getUserFullName, getPaper, getInk, getClient,
    USERS, CLIENTS, PAPERS, INKS, isAdmin, isTemsilci, CONFIG,
  } = useContext(GlobalContext);

  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [filterUser, setUser] = useState([]);
  const [filterClient, setClient] = useState([]);
  const [filterName, setName] = useState('');
  const [filterStatus, setStatus] = useState('');
  const [filterOfferNumber, setOfferNumber] = useState('');

  const defaultColumns = [{
    title: 'Teklif Talep Tarihi',
    dataIndex: 'created_at',
    isCommon: true,
    width: 200,
    render: (date) => formatDate(date),
  }, {
    title: 'Satış Temsilcisi',
    dataIndex: 'client_representative',
    isCommon: false,
    width: 200,
    render: (value) => getUserFullName(value),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          options={USERS.TEMSILCI}
          onChange={(id) => { setUser(id); }}
        />
      </div>
    )),
  }, {
    title: 'Teklif Numarası',
    dataIndex: 'offer_number',
    isCommon: true,
    width: 120,
    render: (value, row) => renderXNumber(row.client_number, value),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Input
          allowClear
          onPressEnter={({ target }) => { setOfferNumber(target.value); }}
          onBlur={({ target }) => { setOfferNumber(target.value); }}
        />
      </div>
    )),
  }, {
    title: 'Firma Ticaret Ünvanı',
    dataIndex: 'client_name',
    isCommon: true,
    width: 200,
    fixed: screens.xl ? 'left' : false,
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          options={CLIENTS}
          onChange={(id) => { setClient(id); }}
        />
      </div>
    )),
  }, {
    title: 'İşin Adı',
    dataIndex: 'name',
    isCommon: true,
    width: 200,
    fixed: 'left',
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Input
          allowClear
          onPressEnter={({ target }) => { setName(target.value); }}
          onBlur={({ target }) => { setName(target.value); }}
        />
      </div>
    )),
    editable: {
      type: 'INPUTTEXT',
      style: {
        width: '150px',
      },
    },
  }, {
    title: 'En (mm)',
    dataIndex: 'en',
    isCommon: true,
    width: 70,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Boy (mm)',
    dataIndex: 'boy',
    isCommon: true,
    width: 70,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Adet',
    dataIndex: 'total_piece',
    isCommon: true,
    width: 110,
    render: (value) => formatK(value, 0),
    editable: {
      type: 'INPUT',
      style: {
        width: '80px',
      },
    },
  }, {
    title: 'Notlar',
    dataIndex: 'notes',
    isCommon: true,
    width: 150,
    editable: {
      type: 'INPUTTEXT',
      isRequired: false,
    },
  }, {
    title: 'Yan Yana',
    dataIndex: 'yan_yana',
    className: 'cell-yan_yana',
    isCommon: false,
    width: 70,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Kağıt Türü',
    dataIndex: 'material',
    isCommon: true,
    width: 170,
    render: (value) => getPaper(value)?.name,
    editable: {
      type: 'SELECT',
      options: PAPERS,
      style: {
        width: '160px',
      },
    },
  }, {
    title: 'Baskı Türü',
    dataIndex: 'ink',
    isCommon: false,
    width: 120,
    render: (value) => getInk(value)?.name,
    editable: {
      type: 'SELECT',
      options: INKS,
      style: {
        width: '180px',
      },
    },
  }, {
    title: 'Kağıt Alış',
    dataIndex: 'material_price',
    isCommon: false,
    width: 100,
  }, {
    title: 'Kağıt Satış Değer Payı',
    dataIndex: 'paper_sales_value',
    isCommon: false,
    width: 110,
    render: (value) => `${value}%`,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Kağıt Satış',
    dataIndex: 'kagit_satis',
    isCommon: false,
    width: 70,
  }, {
    title: 'Bobin En (metre)',
    dataIndex: 'bobin_en',
    isCommon: false,
    width: 100,
  }, {
    title: 'Bobin Boy (metre)',
    dataIndex: 'bobin_boy',
    isCommon: false,
    width: 110,
  }, {
    title: 'Boy Fire',
    dataIndex: 'boy_fire',
    isCommon: false,
    width: 65,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Toplam Boy',
    dataIndex: 'toplam_boy',
    isCommon: false,
    width: 120,
    render: formatK,
  }, {
    title: 'Metrekare',
    dataIndex: 'metrekare',
    isCommon: false,
    width: 110,
  }, {
    title: 'Kağıt Fiyat Toplam',
    dataIndex: 'kagit_fiyat_toplam',
    isCommon: false,
    width: 110,
    render: formatEuro,
  }, {
    title: 'Mürekkep Fiyat Toplam',
    dataIndex: 'murekkep_fiyat_toplam',
    isCommon: false,
    width: 125,
    render: formatEuro,
  }, {
    title: 'Bıçak Fiyat Toplam',
    dataIndex: 'bicak_fiyat',
    className: 'cell-bicak_fiyat',
    isCommon: false,
    width: 115,
    render: formatEuro,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Toplam Maliyet',
    dataIndex: 'ara_toplam',
    isCommon: false,
    width: 140,
    render: formatEuro,
  }, {
    title: 'Fiyat + 170%',
    dataIndex: 'ara_toplam_170',
    isCommon: false,
    width: 130,
    render: formatEuro,
  }, {
    title: 'Fiyat + 160%',
    dataIndex: 'ara_toplam_160',
    isCommon: false,
    width: 130,
    render: formatEuro,
  }, {
    title: 'Fiyat + 150%',
    dataIndex: 'ara_toplam_150',
    isCommon: false,
    width: 130,
    render: formatEuro,
  }, {
    title: 'Fiyat + 140%',
    dataIndex: 'ara_toplam_140',
    isCommon: false,
    width: 130,
    render: formatEuro,
  }, {
    title: 'Fiyat + 130%',
    dataIndex: 'ara_toplam_130',
    isCommon: false,
    width: 130,
    render: formatEuro,
  }, {
    title: 'Flekso Klişe Bedeli',
    dataIndex: 'klise_bedeli',
    isCommon: false,
    width: 120,
    render: formatEuro,
    editable: {
      type: 'INPUT',
      isRequired: false,
    },
  }, {
    title: 'Emboss Kalıp Bedeli',
    dataIndex: 'emboss_kalip_bedeli',
    isCommon: false,
    width: 120,
    render: formatEuro,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Emboss Bedeli',
    dataIndex: 'emboss_bedeli',
    isCommon: false,
    width: 95,
    render: formatEuro,
  }, {
    title: 'Yaldız',
    dataIndex: 'yaldiz',
    isCommon: true,
    width: 85,
    editable: {
      type: 'SELECT',
      options: YALDIZ,
    },
  }, {
    title: 'Yaldız Türü',
    dataIndex: 'yaldiz_type',
    isCommon: true,
    width: 110,
    editable: {
      type: 'SELECT',
      options: YALDIZ_TYPE,
    },
  }, {
    title: 'Yaldız Bedeli',
    dataIndex: 'yaldiz_bedeli',
    isCommon: false,
    width: 120,
    render: formatEuro,
    editable: {
      type: 'INPUT',
      style: {
        width: '120px',
      },
    },
  }, {
    title: 'Yaldız Klişe Bedeli',
    dataIndex: 'yaldiz_klise',
    isCommon: false,
    width: 120,
    render: formatEuro,
    editable: {
      type: 'INPUT',
      style: {
        width: '120px',
      },
    },
  }, {
    title: 'Bıçak',
    dataIndex: 'bicak',
    hideAdmin: true,
    width: 120,
  }, {
    title: 'Lak',
    dataIndex: 'lak',
    hideAdmin: true,
    width: 120,
  }, {
    title: 'Selofan',
    dataIndex: 'selofan',
    isCommon: true,
    width: 90,
    editable: {
      type: 'SELECT',
      options: SELOFAN,
      style: {
        width: '120px',
      },
    },
  }, {
    title: 'Emboss',
    dataIndex: 'emboss',
    hideAdmin: true,
    width: 120,
  }, {
    title: 'Selofan Bedeli',
    dataIndex: 'selofan_bedeli',
    isCommon: false,
    width: 100,
    render: formatEuro,
    editable: {
      type: 'INPUT',
      style: {
        width: '100px',
      },
    },
  }, {
    title: 'Takım / Çeşit',
    dataIndex: 'set_assortment',
    isCommon: true,
    width: 95,
    editable: {
      type: 'SELECT',
      options: SET_ASSORTMENT,
      style: {
        width: '150px',
      },
    },
  }, {
    title: 'Teklif Durumu',
    dataIndex: 'status',
    isCommon: true,
    width: 180,
    editable: {
      type: 'SELECT',
      options: STATUS,
      style: {
        width: '160px',
      },
    },
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          mode={null}
          options={STATUS}
          onChange={(value) => { setStatus(value); }}
        />
      </div>
    )),
  }, {
    title: 'Toplam Fiyat',
    dataIndex: 'toplam_fiyat',
    className: 'cell-toplam_fiyat',
    isCommon: false,
    width: 140,
    fixed: 'right',
    render: formatEuro,
    editable: {
      type: 'INPUT',
      style: {
        width: '100px',
      },
    },
  }, {
    title: 'Teklif Fiyatı',
    dataIndex: 'teklif_fiyat',
    isCommon: true,
    width: 140,
    render: formatEuro,
  }, {
    title: '',
    dataIndex: 'id',
    isCommon: true,
    fixed: 'right',
    render: (id, row) => (
      <Space>
        <Popconfirm
          title="Teklifi kopyalamak istediğine emin misin?"
          okText={config.okText}
          cancelText={config.cancelText}
          onConfirm={() => {
            if (isAdmin) {
              copyOffer(row);
            } else {
              copyOfferWithCreatePage(row);
            }
          }}
        >
          <Button type="default" icon={React.createElement(CopyOutlined)} />
        </Popconfirm>
        {isAdmin && row.status !== OFFER_WIN && (
          <Popconfirm
            title="Teklif fiyatını güncellemek istediğine emin misin?"
            okText={config.okText}
            cancelText={config.cancelText}
            onConfirm={() => { syncOfferPrice(row); }}
          >
            <Button type="primary" icon={React.createElement(RetweetOutlined)} />
          </Popconfirm>
        )}
        {row.status === OFFER_WIN && (
          <Button type="primary" className="btn-success link-like-button">
            <Link to={`${routes.ORDER_CREATE}?id=${id}`}>
              {React.createElement(DatabaseOutlined)}
            </Link>
          </Button>
        )}
        <Popconfirm
          title="Silmek istediğine emin misin?"
          okText={config.okText}
          cancelText={config.cancelText}
          onConfirm={() => { deleteOffer(id); }}
        >
          <Button type="primary" danger icon={React.createElement(DeleteOutlined)} />
        </Popconfirm>
      </Space>
    ),
  }];

  const [isLoading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [notFilteredDataSource, setNFDS] = useState([]);

  async function getData() {
    const w = [];

    if (!auth.currentUser) {
      return;
    }

    setLoading(true);

    if (!isAdmin) w.push(where('created_by', '==', auth.currentUser.uid));
    if (filterClient.length) w.push(where('client_id', 'in', filterClient));
    if (filterUser.length) w.push(where('created_by', 'in', filterUser));
    if (filterStatus?.length) w.push(where('status', '==', filterStatus));

    if (filterOfferNumber?.length) {
      const [clientNumber, offerNumber] = filterOfferNumber.split('-');

      if (clientNumber?.length) {
        w.push(where('client_number', '==', Number.parseInt(clientNumber, 10)));
      }

      if (offerNumber?.length) {
        w.push(where('offer_number', '==', Number.parseInt(offerNumber, 10)));
      }
    }

    const query = queryWhere(collections.OFFERS, and(...w));

    await getDocs(query).then((data) => {
      setDataSource(data);
      setNFDS(data);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, [filterUser, filterClient, filterStatus, filterOfferNumber, isAdmin]);

  useEffect(() => {
    if (filterName?.length) {
      const filtered = notFilteredDataSource
        .filter((row) => row.name.toLowerCase('tr').includes(filterName.toLowerCase('tr')));
      setDataSource(filtered);
    } else if (notFilteredDataSource.length !== dataSource.length) {
      setDataSource(notFilteredDataSource);
    }
  }, [filterName, notFilteredDataSource]);

  async function copyOffer(row) {
    const lastOffer = await getDocs(queryWhere(collections.OFFERS, where('client_id', '==', row.client_id), undefined, undefined, limit(1)));
    const offerNumber = (lastOffer[0]?.offer_number || 0) + 1;
    const client = getClient(row.client_id);

    await addDoc(collections.OFFERS, {
      ...row,
      id: undefined,
      status: STATUS[0].value,
      created_at: undefined,
      created_by: undefined,
      updated_at: undefined,
      updated_by: undefined,
      client_number: client.client_number,
      offer_number: offerNumber,
    });

    onSuccess('Teklif kopyalandı.');
    getData();
  }

  function copyOfferWithCreatePage({ id }) {
    navigate({
      pathname: routes.OFFER_CREATE,
      search: `?id=${id}`,
    });
  }

  async function syncOfferPrice(row) {
    await updateDoc(collections.OFFERS, row.id, {
      ...row,
      teklif_fiyat: row.toplam_fiyat,
    });

    onSuccess('Teklif fiyatı güncellendi.');
    getData();
  }

  async function deleteOffer(id) {
    await deleteDoc(collections.OFFERS, id);
    onSuccess('Teklif silindi.');
    getData();
  }

  function onSuccess(description = '') {
    notificationApi.success({
      message: 'İşlem Başarılı',
      description,
    });
  }

  const EditableContext = React.createContext(null);

  function EditableRow({ index, ...props }) {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  }

  function EditableCell({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave(
          {
            ...record,
            ...values,
          },
          Object.keys(values)[0],
        );
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={(editable.isRequired ?? true) ? required : []}
        >
          {editable.type === 'INPUT' && (
            <InputNumber
              {...numberConf}
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{
                width: '50px',
                ...editable.style,
              }}
            />
          )}

          {editable.type === 'INPUTTEXT' && (
            <Input
              {...numberConf}
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{
                width: '100px',
                ...editable.style,
              }}
            />
          )}

          {editable.type === 'SELECT' && (
            <Select
              ref={inputRef}
              options={editable.options}
              onChange={save}
              onBlur={save}
              style={{
                width: '80px',
                ...editable.style,
              }}
            />
          )}
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...restProps} focus-wrapper={dataIndex}>{childNode}</td>;
  }

  const handleSave = async (row, field) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];

    if (row[field] === item[field]) {
      // Don't save if no change.
      return;
    }

    const newOffer = setOffer(CONFIG, {
      ...row,
      material_price: getPaper(row.material)?.price,
      ink_price: getInk(row.ink)?.price || 0,
      name_search_terms: generateSearchTerms(row.name),
    }, field);

    await updateDoc(collections.OFFERS, row.id, {
      ...newOffer,
      id: undefined,
    });

    newData.splice(index, 1, {
      ...item,
      ...newOffer,
    });

    setDataSource(newData);
    setNFDS(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const fullEditableCols = ['status'];

  const columns = useMemo(() => (
    defaultColumns
      .filter((col) => ((isAdmin && !col.hideAdmin) || (!isAdmin && col.hideAdmin) || col.isCommon))
      .map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: (isAdmin || fullEditableCols.includes(col.dataIndex)) && col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      })
  ), [auth.currentUser, isAdmin, dataSource]);

  const lastFocusItem = useRef(null);
  const handleKeyDown = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault();
      const cell = document.activeElement.closest('[focus-wrapper]');

      if (cell) {
        const row = cell.parentElement.querySelectorAll('[focus-wrapper]');
        const index = [].indexOf.call(row, cell);

        if ((event.shiftKey)) {
          if ((index - 1 >= 0)) {
            row.item(index - 1).querySelector('div').click();
            lastFocusItem.current = {
              row: row.item(index - 1).parentElement.getAttribute('data-row-key'),
              cell: row.item(index - 1).getAttribute('focus-wrapper'),
            };
          }
        } else if (index + 1 < row.length) {
          row.item(index + 1).querySelector('div').click();
          lastFocusItem.current = {
            row: row.item(index + 1).parentElement.getAttribute('data-row-key'),
            cell: row.item(index + 1).getAttribute('focus-wrapper'),
          };
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      lastFocusItem.current = null;
    };
  }, []);

  useEffect(() => {
    if (lastFocusItem.current) {
      document.querySelector(`[data-row-key="${lastFocusItem.current.row}"] [focus-wrapper="${lastFocusItem.current.cell}"]`).querySelector('div').click();
    }
  }, [dataSource]);

  if (!(isAdmin || isTemsilci)) {
    return <Page404 />;
  }

  return (
    <Card title="Tüm Teklifler" bordered={false}>
      <Table
        loading={isLoading}
        dataSource={dataSource}
        columns={columns}
        components={components}
        rowKey="id"
        bordered
        sticky={!!dataSource.length}
        pagination={{
          defaultPageSize: 20,
        }}
        scroll={{
          x: 'max-content',
        }}
      />
      {contextHolder}
    </Card>
  );
}
