import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { GlobalContext } from '../../../../contexts/global';
import { colorsAsArray } from '../../../../utils/colors';
import { chartDefaultOptions, customSort, TOTAL_LABEL } from './common';

export default function OrderCountYearlyGrapher({ data }) {
  const { getUserFullName, isAdmin, userId } = useContext(GlobalContext);

  const chartOptions = {
    ...chartDefaultOptions({
      title: 'Grafik Tasarımcı Bazında Üretilen İş Adedi',
    }),
    indexAxis: 'y',
  };

  function transformData() {
    const getAllDesigners = () => {
      const designers = new Set();

      data.forEach((entry) => {
        Object.keys(entry.sum.byGrapher).forEach((designerId) => {
          designers.add(designerId);
        });
      });

      return Array.from(designers);
    };

    const getSortedDesigners = (allDesigners) => allDesigners
      .filter((grapherId) => isAdmin || userId === grapherId)
      .map((id) => ({
        id,
        name: getUserFullName(id) || TOTAL_LABEL,
      }))
      .sort((a, b) => customSort(a.name, b.name))
      .map((item) => item.id);

    const allDesigners = getAllDesigners();
    const sortedDesigners = getSortedDesigners(allDesigners);

    const calculateTotalByYearAndType = (year, type) => {
      const filteredData = data.filter((d) => d.date.year === year);

      return filteredData.reduce((acc, entry) => {
        Object.entries(entry.sum.byGrapher).forEach(([designerId, values]) => {
          acc[designerId] = (acc[designerId] || 0) + (values[type] || 0);
        });
        return acc;
      }, {});
    };

    const digital2024 = calculateTotalByYearAndType(2024, 'digital');
    const flekso2024 = calculateTotalByYearAndType(2024, 'flekso');
    const digital2025 = calculateTotalByYearAndType(2025, 'digital');
    const flekso2025 = calculateTotalByYearAndType(2025, 'flekso');

    return {
      labels: sortedDesigners.map((id) => getUserFullName(id) || TOTAL_LABEL),
      datasets: [{
        label: '2025 Digital',
        backgroundColor: colorsAsArray[4],
        data: sortedDesigners.map((id) => digital2025[id] || 0),
      }, {
        label: '2025 Flekso',
        backgroundColor: colorsAsArray[5],
        data: sortedDesigners.map((id) => flekso2025[id] || 0),
      }, {
        label: '2024 Digital',
        backgroundColor: colorsAsArray[6],
        data: sortedDesigners.map((id) => digital2024[id] || 0),
        hidden: true,
      }, {
        label: '2024 Flekso',
        backgroundColor: colorsAsArray[7],
        data: sortedDesigners.map((id) => flekso2024[id] || 0),
        hidden: true,
      }],
    };
  }

  return <Bar options={chartOptions} data={transformData()} />;
}

OrderCountYearlyGrapher.propTypes = {
  data: PropTypes.array,
};

OrderCountYearlyGrapher.defaultProps = {
  data: [],
};
