import { DeleteOutlined, DoubleRightOutlined, FilterFilled } from '@ant-design/icons';
import {
  Button, Card, Input, notification, Popconfirm, Select, Space, Table,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { GlobalContext } from '../../contexts/global';
import collections from '../../utils/collections';
import { filterOption, formatDate, renderXNumber } from '../../utils/common';
import {
  and, auth, deleteDoc, getDocs, queryWhere, where,
} from '../../utils/firebase';
import { ORDER_PRINT_TYPES, ORDER_STATUS } from '../../utils/options';
import routes from '../../utils/routes';
import Page404 from '../Page404';

const DATE_FORMAT = 'DD/MM/YYYY';
const config = {
  okText: 'Evet',
  cancelText: 'Hayır',
};
const filterSetup = {
  placeholder: 'Seçiniz',
  filterOption,
  showSearch: true,
  allowClear: true,
  mode: 'tags',
};

function sortHelper(a, b, key) {
  return a[key] - b[key];
}

function filterIcon(isFiltered) {
  return <FilterFilled style={{ color: isFiltered ? '#1677ff' : undefined }} />;
}

export default function Orders() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    getUserFullName, getPaper, CLIENTS, USERS, PAPERS, isAdmin, isTemsilci, isGrafik,
  } = useContext(GlobalContext);

  const [isLoading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [notFilteredDataSource, setNFDS] = useState([]);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [filterClient, setClient] = useState([]);
  const [filterName, setName] = useState('');
  const [filterRepresentative, setRepresentative] = useState([]);
  const [filterGrapher, setGrapher] = useState([]);
  const [filterPrintType, setPrintType] = useState([]);
  const [filterMaterial, setMaterial] = useState([]);
  const [filterStatus, setStatus] = useState([]);
  const [filterOfferNumber, setOfferNumber] = useState('');

  const columns = [{
    title: 'Teklif Numarası',
    dataIndex: ['offer'],
    width: 120,
    render: (offer) => renderXNumber(offer?.client_number, offer?.offer_number),
    filterIcon: filterIcon(filterOfferNumber?.length),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Input
          defaultValue={filterOfferNumber}
          allowClear
          onPressEnter={({ target }) => { setOfferNumber(target.value); }}
          onBlur={({ target }) => { setOfferNumber(target.value); }}
        />
      </div>
    )),
  }, {
    title: 'Firma Ticaret Ünvanı',
    dataIndex: ['offer', 'client_name'],
    isCommon: true,
    filterIcon: filterIcon(filterClient?.length),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          value={filterClient}
          {...filterSetup}
          options={CLIENTS}
          onChange={(id) => { setClient(id); }}
        />
      </div>
    )),
  }, {
    title: 'İşin Adı',
    dataIndex: ['offer', 'name'],
    isCommon: true,
    filterIcon: filterIcon(filterName?.length),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Input
          defaultValue={filterName}
          allowClear
          onPressEnter={({ target }) => { setName(target.value); }}
          onBlur={({ target }) => { setName(target.value); }}
        />
      </div>
    )),
  }, {
    title: 'Satış Temsilcisi',
    dataIndex: ['offer', 'client_representative'],
    isCommon: false,
    render: (id) => getUserFullName(id),
    filterIcon: filterIcon(filterRepresentative?.length),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          value={filterRepresentative}
          {...filterSetup}
          options={USERS.TEMSILCI}
          onChange={(id) => { setRepresentative(id); }}
        />
      </div>
    )),
  }, {
    title: 'Grafiker',
    dataIndex: 'grapher',
    isCommon: true,
    render: (id) => getUserFullName(id),
    filterIcon: filterIcon(filterGrapher?.length),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          value={filterGrapher}
          {...filterSetup}
          options={USERS.GRAFIK}
          onChange={(id) => { setGrapher(id); }}
        />
      </div>
    )),
  }, {
    title: 'Sipariş Tarihi',
    dataIndex: 'order_date',
    isCommon: true,
    render: (date) => formatDate(date, DATE_FORMAT),
    sorter: (a, b) => sortHelper(a, b, 'order_date'),
  }, {
    title: 'Baskı Onay Tarihi',
    dataIndex: 'print_approval_date',
    isCommon: true,
    render: (date) => formatDate(date, DATE_FORMAT),
    sorter: (a, b) => sortHelper(a, b, 'print_approval_date'),
  }, {
    title: 'Üretim Onay Tarihi',
    dataIndex: 'production_start_date',
    isCommon: true,
    render: (date) => formatDate(date, DATE_FORMAT),
    sorter: (a, b) => sortHelper(a, b, 'production_start_date'),
  }, {
    title: 'Baskı Türü',
    dataIndex: 'print_type',
    isCommon: true,
    filterIcon: filterIcon(filterPrintType?.length),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          value={filterPrintType}
          {...filterSetup}
          options={ORDER_PRINT_TYPES}
          onChange={(id) => { setPrintType(id); }}
        />
      </div>
    )),
  }, {
    title: 'Kağıt Türü',
    dataIndex: ['offer', 'material'],
    isCommon: true,
    filterIcon: filterIcon(filterMaterial?.length),
    render: (value) => getPaper(value)?.name,
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          value={filterMaterial}
          {...filterSetup}
          options={PAPERS}
          onChange={(id) => { setMaterial(id); }}
        />
      </div>
    )),
  }, {
    title: 'Durum',
    dataIndex: 'status',
    isCommon: true,
    render: (id) => ORDER_STATUS.find((s) => s.value === id)?.label,
    filterIcon: filterIcon(filterStatus?.length),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          value={filterStatus}
          {...filterSetup}
          options={ORDER_STATUS}
          onChange={(id) => { setStatus(id); }}
        />
      </div>
    )),
  }, {
    title: '',
    dataIndex: 'id',
    isCommon: true,
    render: (orderId) => (
      <Space>
        <Button type="primary" className="link-like-button">
          <Link to={routes.ORDER_DETAIL.replace(':orderId', orderId)}>
            {React.createElement(DoubleRightOutlined)}
          </Link>
        </Button>
        {(isAdmin || isTemsilci) && (
          <Popconfirm
            title="Silmek istediğine emin misin?"
            okText={config.okText}
            cancelText={config.cancelText}
            onConfirm={() => deleteOrder(orderId)}
          >
            <Button type="primary" danger icon={React.createElement(DeleteOutlined)} />
          </Popconfirm>
        )}
      </Space>
    ),
  }];

  useEffect(() => {
    const cid = searchParams.get('cid')?.split('-') || [];
    const rp = searchParams.get('rp')?.split('-') || [];
    const gid = searchParams.get('gid')?.split('-') || [];
    const pt = searchParams.get('pt')?.split('-') || [];
    const mt = searchParams.get('mt')?.split('-') || [];
    const st = searchParams.get('st')?.split('-') || [];
    const cn = searchParams.get('cn');
    const on = searchParams.get('on');
    const name = searchParams.get('name');

    if (cid.length) setClient(cid);
    if (rp.length) setRepresentative(rp);
    if (gid.length) setGrapher(gid);
    if (pt.length) setPrintType(pt);
    if (mt.length) setMaterial(mt);
    if (st.length) setStatus(st.map((s) => Number.parseInt(s, 10)));
    if (cn || on) setOfferNumber(`${cn || ''}-${on || ''}`);
    if (name) setName(name);
  }, []);

  async function getData() {
    const w = [];
    const qp = {};

    if (!auth.currentUser) {
      return;
    }

    setLoading(true);

    if (!(isAdmin || isGrafik)) w.push(where('created_by', '==', auth.currentUser.uid));
    if (filterClient.length) w.push(where('offer.client_id', 'in', filterClient));
    if (filterRepresentative.length) w.push(where('offer.client_representative', 'in', filterRepresentative));
    if (filterGrapher.length) w.push(where('grapher', 'in', filterGrapher));
    if (filterPrintType.length) w.push(where('print_type', 'in', filterPrintType));
    if (filterMaterial.length) w.push(where('offer.material', 'in', filterMaterial));
    if (filterStatus.length) w.push(where('status', 'in', filterStatus));

    if (!(isAdmin || isGrafik)) {
      w.push(where('created_by', '==', auth.currentUser.uid));
    }

    if (filterClient.length) {
      w.push(where('offer.client_id', 'in', filterClient));
      qp.cid = filterClient.join('-');
    }

    if (filterRepresentative.length) {
      w.push(where('offer.client_representative', 'in', filterRepresentative));
      qp.rp = filterRepresentative.join('-');
    }

    if (filterGrapher.length) {
      w.push(where('grapher', 'in', filterGrapher));
      qp.gid = filterGrapher.join('-');
    }

    if (filterPrintType.length) {
      w.push(where('print_type', 'in', filterPrintType));
      qp.pt = filterPrintType.join('-');
    }

    if (filterMaterial.length) {
      w.push(where('offer.material', 'in', filterMaterial));
      qp.mt = filterMaterial.join('-');
    }

    if (filterStatus.length) {
      w.push(where('status', 'in', filterStatus));
      qp.st = filterStatus.join('-');
    }

    if (filterOfferNumber?.length) {
      const [clientNumber, offerNumber] = filterOfferNumber.split('-');

      if (clientNumber?.length) {
        w.push(where('offer.client_number', '==', Number.parseInt(clientNumber, 10)));
        qp.cn = clientNumber;
      }

      if (offerNumber?.length) {
        w.push(where('offer.offer_number', '==', Number.parseInt(offerNumber, 10)));
        qp.on = offerNumber;
      }
    }

    if (filterName?.length) {
      qp.name = filterName;
    }

    const newSearchParams = new URLSearchParams();
    Object.entries(qp).forEach(([key, value]) => {
      if (value) newSearchParams.set(key, value);
    });
    setSearchParams(newSearchParams);

    const query = queryWhere(collections.ORDERS, and(...w));

    await getDocs(query).then((data) => {
      setDataSource(data);
      setNFDS(data);
    });

    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [isAdmin, filterClient, filterRepresentative,
    filterGrapher, filterPrintType, filterMaterial, filterStatus, filterOfferNumber, filterName]);

  function onSuccess(description = '') {
    notificationApi.success({
      message: 'İşlem Başarılı',
      description,
    });
  }

  async function deleteOrder(id) {
    await deleteDoc(collections.ORDERS, id);
    onSuccess('İş emri silindi.');
    getData();
  }

  useEffect(() => {
    if (filterName?.length) {
      const filtered = notFilteredDataSource
        .filter((row) => row.offer.name.toLowerCase('tr').includes(filterName.toLowerCase('tr')));
      setDataSource(filtered);
    } else if (notFilteredDataSource.length !== dataSource.length) {
      setDataSource(notFilteredDataSource);
    }
  }, [filterName, notFilteredDataSource]);

  if (!(isAdmin || isTemsilci || isGrafik)) {
    return <Page404 />;
  }

  return (
    <Card title="Tüm İş Emirleri" bordered={false}>
      <Table
        loading={isLoading}
        dataSource={dataSource}
        columns={columns.filter((col) => (col.isCommon || isAdmin))}
        rowKey="id"
        bordered
        pagination={{
          defaultPageSize: 20,
        }}
        rowClassName={(record) => `order-status-${record.status}`}
      />
      {contextHolder}
    </Card>
  );
}
