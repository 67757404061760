import { SaveOutlined } from '@ant-design/icons';
import {
  Button, Drawer, Form, Input, notification, Select,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import collections from '../../../utils/collections';
import { addDoc } from '../../../utils/firebase';
import { FIELD_WORKERS_TYPE } from '../../../utils/options';

const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];

export default function AddFieldWorker({ open, onClose }) {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();

  function toClose(x) {
    form.resetFields();
    onClose(x);
  }

  async function onSubmit(values) {
    setLoading(true);

    try {
      const row = {
        ...values,
        disabled: false,
      };

      await addDoc(collections.FIELD_WORKERS, row);

      notificationApi.success({
        message: 'İşlem Başarılı',
      });

      toClose(true);
    } catch (error) {
      notificationApi.error({
        message: 'İşlem Başarısız',
      });
    }

    setLoading(false);
  }

  return (
    <Drawer
      title="Üretim Yetkilisi Ekle"
      open={open}
      onClose={toClose}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item name="first_name" label="Ad" rules={required}>
          <Input />
        </Form.Item>
        <Form.Item name="last_name" label="Soyad" rules={required}>
          <Input />
        </Form.Item>
        <Form.Item name="role" label="Rol" rules={required}>
          <Select
            mode="multiple"
            placeholder="Seçiniz"
            options={[{
              label: 'Üretim',
              options: FIELD_WORKERS_TYPE,
            }]}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            icon={React.createElement(SaveOutlined)}
            loading={isLoading}
          >
            Kaydet
          </Button>
        </Form.Item>
      </Form>
      {contextHolder}
    </Drawer>
  );
}

AddFieldWorker.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

AddFieldWorker.defaultProps = {
  onClose: () => { },
};
