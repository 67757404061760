import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { GlobalContext } from '../../../../contexts/global';
import { colorsAsArray } from '../../../../utils/colors';
import {
  chartDefaultOptions, customSort, chartFont, TOTAL_LABEL,
} from './common';

export default function OrderRevenueYearly({ data }) {
  const { getUserFullName } = useContext(GlobalContext);

  const chartOptions = {
    ...chartDefaultOptions({
      title: 'Satış Temsilcisi Bazında Baskı Onayı Alınan İş Cirosu',
      datalabels: ['end', true],
    }),
    indexAxis: 'y',
    scales: {
      x: {
        ticks: {
          font: chartFont,
        },
        afterDataLimits: (scale) => {
          scale.max *= 1.05;
        },
      },
      y: {
        ticks: {
          font: chartFont,
        },
        afterDataLimits: (scale) => {
          scale.max *= 1.05;
        },
      },
    },
  };

  function transformData() {
    const getAllRepresentatives = () => {
      const reps = new Set();

      data.forEach((entry) => {
        Object.keys(entry.sum.byRepresentative).forEach((repId) => {
          reps.add(repId);
        });
      });

      return Array.from(reps);
    };

    const getSortedRepresentatives = (allRepresentatives) => allRepresentatives
      .map((id) => ({
        id,
        name: getUserFullName(id) || TOTAL_LABEL,
      }))
      .sort((a, b) => customSort(a.name, b.name))
      .map((item) => item.id);

    const allRepresentatives = getAllRepresentatives();
    const sortedRepresentatives = getSortedRepresentatives(allRepresentatives);

    const calculateTotalByYear = (year) => {
      const filteredData = data.filter((d) => d.date.year === year);

      return filteredData.reduce((acc, entry) => {
        Object.entries(entry.sum.byRepresentative).forEach(([repId, { price }]) => {
          acc[repId] = (acc[repId] || 0) + price;
        });
        return acc;
      }, {});
    };

    const ciro2024 = calculateTotalByYear(2024);
    const ciro2025 = calculateTotalByYear(2025);

    return {
      labels: sortedRepresentatives.map((id) => getUserFullName(id) || TOTAL_LABEL),
      datasets: [{
        label: '2025',
        backgroundColor: colorsAsArray[0],
        data: sortedRepresentatives.map((id) => ciro2025[id] || 0),
      }, {
        label: '2024',
        backgroundColor: colorsAsArray[1],
        data: sortedRepresentatives.map((id) => ciro2024[id] || 0),
      }],
    };
  }

  return <Bar options={chartOptions} data={transformData()} />;
}

OrderRevenueYearly.propTypes = {
  data: PropTypes.array,
};

OrderRevenueYearly.defaultProps = {
  data: [],
};
