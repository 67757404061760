import { Card, Col, Row } from 'antd';
import {
  BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { GlobalContext } from '../../contexts/global';
import services from '../../services';
import collections from '../../utils/collections';
import { auth, getDocs, queryAll } from '../../utils/firebase';
import Page404 from '../Page404';
import charts from './components/charts';

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

export default function Analytics() {
  const { role, isAdmin } = useContext(GlobalContext);
  const [isLoading, setLoading] = useState(true);
  const [baseData, setBaseData] = useState(null);

  async function getData() {
    if (auth.currentUser) {
      await services.syncAnalytics().catch(() => {});
      await getDocs(queryAll(collections.ANALYTICS, undefined, 'lastUpdate'))
        .then((data) => {
          setBaseData(data);
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  const cols = useMemo(() => (
    Object.entries(charts).filter(([, chart]) => (isAdmin || chart.role === role))), [role]);

  return (
    cols.length ? (
      <Card title="Raporlamalar" bordered={false} loading={isLoading}>
        <Row gutter={[16, 16]}>
          {cols.map(([name, chart]) => (
            <Col key={name} className="gutter-row" span={24}>
              <div className="chart-wrapper">
                <chart.component data={baseData} />
              </div>
            </Col>
          ))}
        </Row>
      </Card>
    ) : <Page404 />
  );
}
