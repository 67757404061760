import { formatEuroNoDecimal } from '../../../../utils/common';

export const TOTAL_LABEL = 'Toplam Şirket';
export const MONTH_FORMAT = 'MMMM YYYY';

export const SORT = {
  byRepresentative: [
    TOTAL_LABEL,
    'Sadık Balibeyoğlu',
    'Ege Erdem',
    'Tuna Yaşkın',
    'Dijital Satış',
  ],
  byGrapher: [
    TOTAL_LABEL,
  ],
};

export function customSort(a, b, sortBy = 'byRepresentative') {
  const indexA = SORT[sortBy].indexOf(a);
  const indexB = SORT[sortBy].indexOf(b);

  if (indexA === -1 && indexB === -1) return a.localeCompare(b);
  if (indexA === -1) return 1;
  if (indexB === -1) return -1;
  return indexA - indexB;
}

export const chartFont = {
  weight: '600',
  size: 13,
};

export function chartDefaultOptions({
  title = '',
  legend = true,
  tooltip = false,
  datalabels = ['end', false, true],
}) {
  return {
    responsive: true,
    minBarLength: 5,
    plugins: {
      legend: {
        display: legend,
        labels: {
          font: chartFont,
        },
      },
      title: {
        display: !!title.length,
        text: title,
        font: {
          ...chartFont,
          size: 14,
        },
      },
      tooltip: {
        enabled: tooltip,
      },
      datalabels: {
        anchor: datalabels[0] || 'end',
        align: datalabels[0] || 'end',
        display: datalabels[2] || true,
        formatter: (value) => (
          (datalabels[1] || false) ? formatEuroNoDecimal(Math.floor(value)) : value),
        font: chartFont,
      },
    },
    scales: {
      x: {
        ticks: {
          font: chartFont,
        },
      },
      y: {
        ticks: {
          font: chartFont,
        },
      },
    },
  };
}
