import { DeleteOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Button, Card, Popconfirm, Space, Table, Tag,
} from 'antd';
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../contexts/global';
import collections from '../../utils/collections';
import { updateDoc } from '../../utils/firebase';
import { FIELD_WORKERS_TYPE } from '../../utils/options';
import Page404 from '../Page404';
import AddFieldWorker from './components/addFieldWorker';
import UpdateFieldWorker from './components/updateFieldWorker';

export default function Field() {
  const columns = [{
    title: 'Ad',
    dataIndex: 'first_name',
  }, {
    title: 'Soyad',
    dataIndex: 'last_name',
  }, {
    title: 'Rol',
    dataIndex: 'role',
    render: (role) => {
      const roles = FIELD_WORKERS_TYPE.filter((item) => role.includes(item.value));
      return roles.map((item) => <Tag color={item.color}>{item.label}</Tag>);
    },
  }, {
    title: '',
    dataIndex: 'id',
    width: 56,
    render: (id, item) => (item.disabled ? (
      'Pasif'
    ) : (
      <Space>
        <Button
          type="primary"
          icon={React.createElement(SyncOutlined)}
          onClick={() => {
            setUpdate(item);
          }}
        />
        <Popconfirm
          title="Çalışanı pasife çekmek istediğinize emin misin?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={async () => {
            await updateDoc(collections.FIELD_WORKERS, id, {
              ...item,
              disabled: true,
              id: undefined,
            });

            syncFieldWorkers();
          }}
        >
          <Button
            danger
            type="primary"
            icon={React.createElement(DeleteOutlined)}
          />
        </Popconfirm>
      </Space>
    )),
  },
  ];

  const [showAdd, setAdd] = useState(false);
  const [showUpdate, setUpdate] = useState(null);

  const {
    isAdmin, syncFieldWorkers, FIELD_WORKERS,
  } = useContext(GlobalContext);

  if (!isAdmin) {
    return <Page404 />;
  }

  return (
    <Card
      title="Üretim Yetkilileri"
      bordered={false}
      extra={(
        <Button
          type="primary"
          icon={React.createElement(PlusOutlined)}
          onClick={() => {
            setAdd(true);
          }}
        />
      )}
    >
      <Table
        dataSource={FIELD_WORKERS}
        columns={columns}
        rowKey="id"
        bordered
        pagination={false}
      />
      <AddFieldWorker
        open={showAdd}
        onClose={(x) => {
          if (x) {
            syncFieldWorkers();
          }

          setAdd(false);
        }}
      />
      <UpdateFieldWorker
        open={showUpdate}
        onClose={(x) => {
          if (x) {
            syncFieldWorkers();
          }

          setUpdate(null);
        }}
      />
    </Card>
  );
}
