export default {
  HOME: '/',
  LOGIN: '/login',
  CLIENTS: '/clients',
  CLIENT_CREATE: '/clients/create',
  OFFERS: '/offers',
  OFFER_CREATE: '/offers/create',
  CONFIG_PRICING: '/config/pricing',
  CONFIG_USERS: '/config/users',
  CONFIG_FIELD: '/config/field',
  STOCK_PAPER: '/stock/paper',
  STOCK_PAPER_IN: '/stock/paper/in',
  STOCK_PAPER_OUT: '/stock/paper/out',
  STOCK_PAPER_CONFIG: '/stock/paper/config',
  ORDERS: '/orders',
  ORDER_CREATE: '/orders/upsert',
  ORDER_DETAIL: '/orders/:orderId',
  PRINT_ORDER: '/print/orders/:orderId',
  ANALYTICS: '/analytics',
};
