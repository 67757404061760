import moment from 'moment-timezone';
import dayjs from 'dayjs';
import dayUtc from 'dayjs/plugin/utc';
import dayTimezone from 'dayjs/plugin/timezone';
import 'moment/locale/tr';

dayjs.locale('tr');
dayjs.extend(dayUtc);
dayjs.extend(dayTimezone);

const tz = 'Europe/Istanbul';

export const formatDate = (value, format = 'DD MMMM YYYY, HH:mm', isUnix = true) => (value ? (isUnix ? moment.unix(value.seconds || value) : moment(value)).tz(tz).locale('tr').format(format) : '');

export const convertDayDate = (value) => dayjs.unix(value).tz(tz);

export const nowDay = () => dayjs().tz(tz);

export const roundFloat = (number, decimalSize = 2) => {
  const factor = 10 ** decimalSize;
  return Math.round((number + Number.EPSILON) * factor) / factor;
};

export const formatK = (value, decimalSize) => (value.toLocaleString('usa', {
  minimumFractionDigits: Number.isInteger(decimalSize) ? decimalSize : 2,
}));

export const formatEuro = (value = 0) => (`€ ${formatK(roundFloat(value))}`);

export const formatEuroNoDecimal = (value = 0) => (`€ ${formatK(roundFloat(value), 0)}`);

export const filterOption = (input, option) => (option.label || '').toLowerCase('tr').includes(input.toLowerCase('tr'));

export const createOfferValues = ['client_id', 'name', 'en', 'boy', 'total_piece', 'material', 'set_assortment', 'bicak', 'lak', 'selofan', 'emboss', 'yaldiz', 'notes'];

export const generateSearchTerms = (input) => {
  const words = input.toLocaleLowerCase('tr').split(/\s+/);
  const results = new Set();

  words.forEach((word) => results.add(word));

  // function permute(arr, m = []) {
  //   if (arr.length === 0) {
  //     results.add(m.join(' '));
  //   } else {
  //     for (let i = 0; i < arr.length; i++) {
  //       const curr = arr.slice();
  //       const next = curr.splice(i, 1);
  //       permute(curr, m.concat(next));
  //     }
  //   }
  // }

  // permute(words);

  return Array.from(results);
};

export const sumArray = (arr) => arr.reduce((acc, num) => acc + num, 0);

export const renderXNumber = (first, second) => (first && second ? `#${first}-${second}` : first ? `#${first}` : '');
