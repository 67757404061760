/* eslint-disable react/prop-types */
import React, {
  useContext, useEffect, useState, useMemo, useRef,
} from 'react';
import {
  Card, Table, Select, Form, Input, Button, Popconfirm,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import collections from '../../utils/collections';
import { generateSearchTerms, renderXNumber } from '../../utils/common';
import {
  auth, queryWhere, getDocs, where, deleteDoc, updateDoc, or, and,
} from '../../utils/firebase';
import { GlobalContext } from '../../contexts/global';
import Page404 from '../Page404';

const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];

export default function Clients() {
  const {
    getUserFullName, isAdmin, isTemsilci, syncClients, USERS,
  } = useContext(GlobalContext);

  const defaultColumns = [{
    title: 'Müşteri Temsilcisi',
    dataIndex: 'representative_id',
    isCommon: true,
    isRequired: true,
    render: (value) => getUserFullName(value),
    editable: {
      type: 'SELECT',
      options: USERS.TEMSILCI,
    },
  }, {
    title: 'Müşteri Numarası',
    dataIndex: 'client_number',
    isCommon: true,
    width: 100,
    render: (value) => renderXNumber(value),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Input
          allowClear
          onPressEnter={({ target }) => { setClientNumber(target.value); }}
          onBlur={({ target }) => { setClientNumber(target.value); }}
        />
      </div>
    )),
  }, {
    title: 'Firma Ticaret Ünvanı',
    dataIndex: 'name',
    isCommon: true,
    isRequired: true,
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Input
          allowClear
          onPressEnter={({ target }) => { setName(target.value); }}
          onBlur={({ target }) => { setName(target.value); }}
        />
      </div>
    )),
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Yetkili - İsim Soyisim',
    dataIndex: 'name_surname',
    isCommon: true,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Telefon Numarası',
    dataIndex: 'phone_number',
    isCommon: true,
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Input
          allowClear
          onPressEnter={({ target }) => { setPhoneNumber(target.value); }}
          onBlur={({ target }) => { setPhoneNumber(target.value); }}
        />
      </div>
    )),
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Email',
    dataIndex: 'email',
    isCommon: true,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Adres',
    dataIndex: 'address',
    isCommon: true,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Vergi Dairesi',
    dataIndex: 'tax_office',
    isCommon: true,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: 'Anlaşılan Çalışma Koşulları ve Diğer Notlar',
    dataIndex: 'notes',
    isCommon: true,
    width: 220,
    editable: {
      type: 'INPUT',
    },
  }, {
    title: '',
    dataIndex: 'id',
    isCommon: false,
    fixed: 'right',
    render: (id) => (
      <Popconfirm
        title="Silmek istediğine emin misin?"
        description="Bu işlem geri alınamaz."
        okText="Evet"
        cancelText="Hayır"
        onConfirm={() => {
          deleteDoc(collections.CLIENTS, id).then(() => {
            getData();
            syncClients();
          });
        }}
      >
        <Button type="primary" danger icon={React.createElement(DeleteOutlined)} />
      </Popconfirm>
    ),
  }];

  const [isLoading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [notFilteredDataSource, setNFDS] = useState([]);

  const [filterName, setName] = useState('');
  const [filterPhoneNumber, setPhoneNumber] = useState('');
  const [filterClientNumber, setClientNumber] = useState('');

  useEffect(() => {
    if (filterName?.length) {
      const filtered = notFilteredDataSource
        .filter((row) => row.name.toLowerCase('tr').includes(filterName.toLowerCase('tr')));
      setDataSource(filtered);
    } else if (notFilteredDataSource.length !== dataSource.length) {
      setDataSource(notFilteredDataSource);
    }
  }, [filterName, notFilteredDataSource]);

  async function getData() {
    const w = [];

    if (!isAdmin) {
      w.push(where('representative_id', '==', auth?.currentUser?.uid));
    }

    if (filterPhoneNumber?.length) {
      w.push(or(where('phone_number', '==', filterPhoneNumber), where('phone_number', '==', `0${filterPhoneNumber}`)));
    }

    if (filterClientNumber?.length) {
      w.push(where('client_number', '==', Number.parseInt(filterClientNumber, 10)));
    }

    if (auth.currentUser) {
      await getDocs(queryWhere(collections.CLIENTS, and(...w)))
        .then((data) => {
          setDataSource(data);
          setNFDS(data);
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    getData();
  }, [auth.currentUser, isAdmin, filterPhoneNumber, filterClientNumber]);

  const EditableContext = React.createContext(null);

  function EditableRow({ index, ...props }) {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  }

  function EditableCell({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    isRequired,
    ...restProps
  }) {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave(
          {
            ...record,
            ...values,
          },
          Object.keys(values)[0],
        );
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={isRequired ? required : []}
        >
          {editable.type === 'INPUT' && (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{
                width: '160px',
                ...editable.style,
              }}
            />
          )}

          {editable.type === 'SELECT' && (
            <Select
              ref={inputRef}
              options={editable.options}
              onChange={save}
              style={{
                width: '160px',
                ...editable.style,
              }}
            />
          )}
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  }

  const handleSave = async (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];

    await updateDoc(collections.CLIENTS, row.id, {
      ...row,
      name_search_terms: generateSearchTerms(row.name),
      id: undefined,
    });

    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setDataSource(newData);
    setNFDS(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = useMemo(() => (
    defaultColumns
      .filter((col) => ((isAdmin && !col.hideAdmin) || (!isAdmin && col.hideAdmin) || col.isCommon))
      .map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: isAdmin && col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            isRequired: col.isRequired || false,
            handleSave,
          }),
        };
      })
  ), [auth.currentUser, isAdmin, dataSource]);

  if (!(isAdmin || isTemsilci)) {
    return <Page404 />;
  }

  return (
    <Card title="Müşteri Listesi" bordered={false}>
      <Table
        loading={isLoading}
        dataSource={dataSource}
        columns={columns.filter((col) => (col.isCommon || isAdmin))}
        components={components}
        rowKey="id"
        bordered
        scroll={{
          x: 'max-content',
        }}
        pagination={{ hideOnSinglePage: true }}
      />
    </Card>
  );
}
