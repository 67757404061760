export const colors = {
  coralBlush: '#FF6F61',
  skyBlue: '#56CCF2',
  sunflowerYellow: '#FFC107',
  lavenderBloom: '#B388EB',
  freshLime: '#A7D129',
  deepTurquoise: '#2C7DA0',
  warmMauve: '#B56576',
  royalPurple: '#6D5ACF',
  sunsetPeach: '#F4A261',
  mistyCyan: '#76EEC6',
  caramelTan: '#D4A373',
  tealWave: '#008C8C',
  apricotGlow: '#E07A5F',
  softPink: '#F28D95',
  honeyAmber: '#E69F66',
  sageGreen: '#A8B5A2',
  clearAqua: '#1DACE8',
  deepPlum: '#5B2C6F',
  oliveLeaf: '#7D9D72',
  gentleLilac: '#9D7CBF',
  rosewood: '#D95D39',
  sandDune: '#C7A17A',
  springGreen: '#4CAF50',
  softMint: '#98DFAF',
  goldenHour: '#FFB347',
};

export const colorsAsArray = Object.values(colors);

export function darkenColor(hex, percent) {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  r = Math.floor(r * (1 - percent));
  g = Math.floor(g * (1 - percent));
  b = Math.floor(b * (1 - percent));

  return `rgb(${r}, ${g}, ${b})`;
}

export function lighterColor(hex, percent) {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  r = Math.min(255, Math.floor(r + (255 - r) * percent));
  g = Math.min(255, Math.floor(g + (255 - g) * percent));
  b = Math.min(255, Math.floor(b + (255 - b) * percent));

  return `rgb(${r}, ${g}, ${b})`;
}
