import React, {
  useState, useContext, useEffect, useMemo,
} from 'react';
import { Layout, Menu as AntMenu } from 'antd';
import { useLocation } from 'react-router-dom';
import routes from '../utils/routes';
import { auth } from '../utils/firebase';
import {
  common, temsilci, isemri, uretim, analytics, admin, bottom,
} from '../utils/menu';
import { GlobalContext } from '../contexts/global';

const { Sider } = Layout;
const defaultOpenMenuKeys = ['_CLIENTS', '_OFFERS', '_ORDERS', '_STOCK', '_STOCK_PAPER'];

export default function Menu() {
  const location = useLocation();
  const [currentMenu, setMenu] = useState([...common, ...bottom]);
  const {
    isAdmin, isTemsilci, isUretim, isGrafik, siderCollapsed, setSiderCollapsed,
  } = useContext(GlobalContext);
  const selectedKeys = useMemo(() => (Object.entries(routes)
    .find((route) => route[1].includes(location.pathname)) || [])[0], [location]);

  useEffect(() => {
    const menu = [...common];

    if (isAdmin || isTemsilci) menu.push(...temsilci);
    if (isAdmin || isTemsilci || isGrafik) menu.push(...isemri);
    if (isAdmin || isUretim || isGrafik) menu.push(...uretim);
    if (isAdmin || isTemsilci) menu.push(...analytics);
    if (isAdmin) menu.push(...admin);
    menu.push(...bottom);

    setMenu(menu);
  }, [auth.currentUser, isAdmin, isTemsilci, isUretim, isGrafik]);

  return (
    <Sider
      width={250}
      collapsible
      collapsed={siderCollapsed}
      breakpoint="lg"
      onCollapse={(value) => setSiderCollapsed(value)}
    >
      <AntMenu
        theme="dark"
        mode="inline"
        items={currentMenu}
        defaultOpenKeys={defaultOpenMenuKeys}
        selectedKeys={selectedKeys}
        inlineIndent={12}
      />
    </Sider>
  );
}
