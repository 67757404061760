import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { GlobalContext } from '../../../../contexts/global';
import { colorsAsArray } from '../../../../utils/colors';
import { formatDate } from '../../../../utils/common';
import {
  chartDefaultOptions, chartFont, customSort, MONTH_FORMAT, TOTAL_LABEL,
} from './common';

export default function OrderRevenueMonthly({ data }) {
  const chartRef = useRef(null);
  const { getUserFullName, isAdmin, userId } = useContext(GlobalContext);

  const chartOptions = {
    ...chartDefaultOptions({
      title: 'Aylık Baskı Onayı Alınan İş Cirosu',
      datalabels: ['end', true],
    }),
    scales: {
      x: {
        ticks: {
          font: chartFont,
          callback: (value, index) => {
            if (chartRef.current) {
              return formatDate(chartRef.current.data.labels[index], MONTH_FORMAT, false);
            }
          },
        },
      },
      y: {
        ticks: {
          font: chartFont,
        },
        afterDataLimits: (scale) => {
          scale.max *= 1.05;
        },
      },
    },
  };

  function transformData() {
    const allMonths = new Set();
    const allRepresentatives = new Set();
    const monthlyData = {};

    data.forEach((entry) => {
      const { year, month } = entry.date;
      const monthKey = `${year}-${month.toString().padStart(2, '0')}`;
      allMonths.add(monthKey);

      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = {};
      }

      Object.entries(entry.sum.byRepresentative).forEach(([repId, repData]) => {
        allRepresentatives.add(repId);
        monthlyData[monthKey][repId] = repData.price;
      });
    });

    const sortedRepresentatives = [...allRepresentatives]
      .filter((repId) => isAdmin || userId === repId)
      .map((repId) => ({ id: repId, name: getUserFullName(repId) || TOTAL_LABEL }))
      .sort((a, b) => customSort(a.name, b.name));

    const sortedLabels = Array.from(allMonths).sort((a, b) => a.localeCompare(b));

    const datasets = sortedRepresentatives.map((rep, index) => ({
      label: rep.name,
      data: sortedLabels.map((monthKey) => monthlyData[monthKey][rep.id] || 0),
      backgroundColor: colorsAsArray[13 + index],
      hidden: rep.name === TOTAL_LABEL,
    }));

    return {
      labels: sortedLabels,
      datasets,
    };
  }

  return <Bar ref={chartRef} options={chartOptions} data={transformData()} />;
}

OrderRevenueMonthly.propTypes = {
  data: PropTypes.array,
};

OrderRevenueMonthly.defaultProps = {
  data: [],
};
