/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import {
  Card, Form, Button, Input, InputNumber, Row, Col, Select, Space,
} from 'antd';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import pick from 'lodash.pick';
import routes from '../../utils/routes';
import collections from '../../utils/collections';
import {
  addDoc, getDoc, getDocs, queryWhere, auth, limit, where,
} from '../../utils/firebase';
import {
  filterOption, createOfferValues, formatK, generateSearchTerms,
} from '../../utils/common';
import { setOffer } from '../../utils/calc';
import { GlobalContext } from '../../contexts/global';
import {
  SET_ASSORTMENT, YV, LAK, SELOFAN, YALDIZ, YALDIZ_TYPE, YOK,
} from '../../utils/options';
import Page404 from '../Page404';

const { TextArea } = Input;
const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];
const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};
const formItemLayoutHorizontal = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const numberConf = {
  controls: false,
  min: 0,
  decimalSeparator: ',',
  precision: 0,
  style: {
    width: '100%',
  },
};
const DEFAULT_INK_ID = process.env.REACT_APP_DEFAULT_INK_ID;

export default function OffersCreate() {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    CLIENTS, PAPERS, getClient, getPaper, getInk, isAdmin, isTemsilci, CONFIG,
  } = useContext(GlobalContext);
  const yaldizValue = Form.useWatch('yaldiz', form);

  async function onSubmit(values) {
    setSaveLoading(true);

    const lastOffer = await getDocs(queryWhere(collections.OFFERS, where('client_id', '==', values.client_id), undefined, undefined, limit(1)));
    const offerNumber = (lastOffer[0]?.offer_number || 0) + 1;

    const client = getClient(values.client_id);
    const material_price = getPaper(values.material)?.price;
    const ink = getInk(values.ink || DEFAULT_INK_ID);

    const offer = setOffer(CONFIG, {
      ...values,
      client_name: client.label,
      client_representative: client.representative_id,
      client_number: client.client_number,
      material_price,
      ink: ink.id,
      ink_price: ink.price,
      name_search_terms: generateSearchTerms(values.name),
      offer_number: offerNumber,
    });

    await addDoc(collections.OFFERS, offer);

    setSaveLoading(false);
    navigate(routes.OFFERS);
  }

  useEffect(() => {
    if (searchParams.get('id')) {
      loadPreData(searchParams.get('id'));
    }
  }, [searchParams]);

  async function loadPreData(id) {
    setLoading(true);

    await getDoc(collections.OFFERS, id)
      .then((data) => {
        if (!isAdmin && data.created_by !== auth.currentUser.uid) {
          return false;
        }

        form.setFieldsValue(pick(data, createOfferValues));
      }).finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    form.setFieldsValue({
      lak: YOK,
      selofan: YOK,
      emboss: YOK,
      yaldiz: YOK,
    });
  }, []);

  if (!(isAdmin || isTemsilci)) {
    return <Page404 />;
  }

  return (
    <Row>
      <Col lg={24} xl={18}>
        <Card title="Yeni Teklif Oluştur" bordered={false} loading={isLoading}>
          <Form
            form={form}
            onFinish={onSubmit}
            autoComplete="off"
            layout="horizontal"
          >
            <p className="form-title">Firma Bilgileri</p>
            <Form.Item
              {...formItemLayout}
              name="client_id"
              label="Firma"
              rules={required}
            >
              <Select
                placeholder="Seçiniz"
                options={CLIENTS}
                showSearch
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="name"
              label="İşin Adı"
              rules={required}
            >
              <Input />
            </Form.Item>
            <Row>
              <Col span={24}>
                <p className="form-title">Ölçü Bilgileri</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="en"
                  label="En (mm)"
                  rules={required}
                >
                  <InputNumber {...numberConf} />
                </Form.Item>
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="boy"
                  label="Boy (mm)"
                  rules={required}
                >
                  <InputNumber {...numberConf} />
                </Form.Item>
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="total_piece"
                  label="Toplam Adet"
                  rules={required}
                >
                  <InputNumber
                    {...numberConf}
                    formatter={(value, info) => {
                      if (!info.userTyping) {
                        let val = info.input === 'undefined' || info.input === '' ? value : info.input;
                        val = val.replaceAll(',', '').replaceAll('.', '');
                        const f = formatK(Number.parseInt(val, 10), 0);
                        return !val.length ? '' : f;
                      }
                    }}
                    parser={(value) => (value.replace(',', '').replace('.', ''))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="material"
                  label="Kağıt Türü"
                  rules={required}
                >
                  <Select
                    placeholder="Seçiniz"
                    options={PAPERS}
                    showSearch
                    filterOption={filterOption}
                  />
                </Form.Item>
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="set_assortment"
                  label="Takım / Çeşit"
                  rules={required}
                >
                  <Select placeholder="Seçiniz" options={SET_ASSORTMENT} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className="form-title">Diğer Bilgiler</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="bicak"
                  label="Bıçak"
                  rules={required}
                >
                  <Select placeholder="Seçiniz" options={YV} />
                </Form.Item>
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="lak"
                  label="Lak"
                  rules={required}
                >
                  <Select placeholder="Seçiniz" options={LAK} />
                </Form.Item>
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="selofan"
                  label="Selofan"
                  rules={required}
                >
                  <Select placeholder="Seçiniz" options={SELOFAN} />
                </Form.Item>
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="emboss"
                  label="Emboss"
                  rules={required}
                >
                  <Select placeholder="Seçiniz" options={YV} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="yaldiz"
                  label="Yaldız"
                  rules={required}
                >
                  <Select placeholder="Seçiniz" options={YALDIZ} />
                </Form.Item>
                {yaldizValue !== YOK && (
                  <Form.Item
                    {...formItemLayoutHorizontal}
                    name="yaldiz_type"
                    label="Yaldız Türü"
                    rules={required}
                  >
                    <Select placeholder="Seçiniz" options={YALDIZ_TYPE} />
                  </Form.Item>
                )}
                <Form.Item
                  {...formItemLayoutHorizontal}
                  name="notes"
                  label="Diğer Notlar"
                >
                  <TextArea
                    rows={4}
                    style={{
                      resize: 'none',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              style={{
                textAlign: 'right',
              }}
            >
              <Space>
                <Button
                  type="primary"
                  size="large"
                  danger
                  icon={React.createElement(CloseOutlined)}
                  onClick={() => { navigate(routes.OFFERS); }}
                >
                  İptal
                </Button>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  icon={React.createElement(SaveOutlined)}
                  loading={saveLoading}
                >
                  Kaydet
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
